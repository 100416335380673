.post {
  @include grid-container;
  .post-meta {
    margin-left: 0;
    display: block;
  }

  .post-excerpt {
    @include grid-column(9);
    margin-left: 0;
  }

  .post-image {
    max-width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
    max-height: 150px;
  }

  .post-navigation {
    text-align: center;
  }

  @include media-query($on-palm) {
    .post-excerpt {
      @include grid-column(12);
      margin-left: 0;
    }

    .post-image {
      @include grid-column(12);
      margin: auto;
    }
  }
}

.page-navigation {
  .pager {
    text-align: center;
    list-style: none;

    li {
      display: inline;
    }
  }
}

.rss-subscribe {
  @include grid-column(12);
  margin-left: 0;
}

.post-content {
  .post-banner-image > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 250px;
  }

  .lightbox-container {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-query($on-palm) {
    .lightbox-container {
      width: 100%;
    }
  }

  .lightbox-image-link {
    display: inline-block;
  }

  .lightbox-image {
    margin: 2px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    width: 100px;
    height: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #4ae;
    }
  }
}

.twitter-plug-container {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.list-2-column {
  columns: 2;
  list-style-position: inside;
}

.ba-vehicle-statblock {
  overflow: hidden;
  padding-bottom: 10px;

  .image-container {
    @include grid-column(4);
	img {
	  max-height: 250px;
	}
  }
  .stats-container {
    @include grid-column(8);
	.row {
      @include grid-column(12);
    }
    .header {
      font-weight: bold;
      font-variant: small-caps;
    }
    .col-3 {
      @include grid-column(3);
    }
    .col-9 {
      @include grid-column(9);
    }
    .options-list, .special-rules-list {
      margin-left: 0;
      list-style: disc outside none;
      padding-left: 1em;
      li {
        padding-left: 0;
      }
    }
  }
}
